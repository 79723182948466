import mobileLayout from '@/views/mobile/layout/layout.vue'
export default {
	path: '/mobile',
	name: 'mobile',
	component: mobileLayout,
	redirect: '/mobile/index',
	children: [
		{
			path: '/mobile/index',
			name: '首页',
			component: () => import('@/views/mobile/index/index.vue'),
		},
        {
            path: "/mobile/login",
            name: "登录",
            component: () => import("@/views/mobile/login/login.vue"),
          },
		{
			path: '/mobile/data',
			name: '数据',
			component: () => import('@/views/mobile/data/data.vue'),
		},
		{
			path: '/mobile/course',
			name: '课程列表',
			component: () => import('@/views/mobile/course/course.vue'),
		},
		{
			path: '/mobile/courseInfo',
			name: '课程详情',
			component: () => import('@/views/mobile/course/courseInfo.vue'),
		},
		{
			path: '/mobile/scene',
			name: '场景',
			component: () => import('@/views/mobile/scene/scene.vue'),
		},
		{
			path: '/mobile/scenceInfo',
			name: '场景详情',
			component: () => import('@/views/mobile/scene/scenceInfo.vue'),
		},
	],
}

<template>
  <div class="footer-root">
    <div class="containar">
      <div class="left">
        <!-- <el-image class="logo" :src="logo" fit="cover"></el-image> -->
        <div class="link">
          <p v-for="(item, i) in linkArr" :key="i" class="link-item">
            <span v-if="item.title">{{ item.title }}：</span>
            <span v-if="item.text">
              <a v-if="item.url" :href="item.url" :target="item.blank ? '_blank' : '_self'">{{ item.text }}</a>
              <span v-else>{{ item.text }}</span>
            </span>
            <!-- <span class="link-item">友情链接：</span> -->
          </p>
          <!-- <p v-if="$store.state.isLogin&&$store.state.userInfo.roleCode!='student'" class="link-item"> -->
          <!-- <p class="link-item">
            <span>后台链接：</span>
            <a target="_blank" href="https://edures.unidmr.com:8000">https://edures.unidmr.com:8000</a>
          </p> -->
          <!-- <p class="link-item lianjie">
            {{ outLink }}
          </p> -->
        </div>
      </div>
      <div class="right">
        <div class="qrcode">
          <img src="../../assets/img/wxqrcode1.jpg" alt="" />
          <!-- <p>小程序二维码</p> -->
        </div>
        <!-- <div class="text">
          <p>技术支持：</p>
          <p>联众竞达</p>
        </div> -->
        <!-- <span></span>
        <span></span>
        <span></span> -->
      </div>
    </div>
    <div class="provider">
      <div>技术支持：四川纵横六合科技股份有限公司</div>
      <div>©2021- 四川联众竞达科技有限公司 版权所有 <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">蜀ICP备2022001491号-1</a></div>
<!--      <div v-for="item in providers" :key="item">{{ item }}</div>-->
    </div>
  </div>
</template>

<script>
// import img1 from "@/assets/img/u179.svg";
export default {
  data() {
    return {
      // logo: img1,
      // 链接地址跳转数据
      linkArr: [
        // { title: "服务协议", url: "", blank: "", text: "" },
        // { title: "通知公告", url: "/notice/index", blank: "", text: "" },
        { title: "技术支持", url: "", blank: false, text: "" },
        { title: "", url: "http://www.cdzh.net", blank: true, text: "四川纵横六合科技股份有限公司" },
        { title: "", url: "http://unidmr.com", blank: true, text: "四川联众竞达科技有限公司" },
        { title: "客服电话", url: "", blank: false, text: "400 880 8689" },
        // { title: "网站地图", url: "", blank: "_blank" },
      ],
      // providers: ["技术支持：四川纵横六合科技股份有限公司", "©2021- 四川联众竞达科技有限公司 版权所有 蜀ICP备2021005496号"],
      providers: ["技术支持：四川纵横六合科技股份有限公司", "©2021- 四川联众竞达科技有限公司 版权所有 蜀ICP备2022001491号-1"],
      // 外部链接地址 武侯区教育局
      // outLink: "http://www.cdwh.gov.cn/wuhou/c121544/green_jyj.shtml",
    };
  },
};
</script>

<style lang="less" scoped>
@btn-color: #f66866;
@title-color: #0079fe;
.footer-root {
  /* position: fixed;
  width: 100%;
  height: 280px;
  z-index: 99;
  bottom: 0;
  left: 0; */
  padding-top: 20px;
  padding-bottom: 30px;
  box-sizing: border-box;
  background-color: #444;
  & > .containar {
    display: flex;
    justify-content: space-between;
    padding: 7px 0;
    .left {
      display: flex;
      font-size: 14px;
      // .logo {
      //   margin-right: 10px;
      //   width: 80px;
      //   height: 120px;
      //   border-radius: 50%;
      // }
      .link {
        display: flex;
        flex-direction: column;
        justify-content: center;
        .link-item,
        a {
          line-height: 2em;
          color: #fff;
        }
        a:hover {
          color: @title-color;
        }
      }
    }
    .right {
      // display: flex;
      // max-width: 600px;
      .qrcode {
        // margin-right:40px;
        img {
          height: 128px;
          width: 128px;
          background-color: #fff;
        }
        // p {
        //   line-height: 20px;
        //   font-size: 12px;
        //   color: #ccc;
        //   text-align: center;
        // }
      }
      // .text {
      //   padding-top:20px;
      //   flex: 1;
      //   color: #ccc;
      //   font-size: 14px;
      //   line-height: 2em;
      // }
      // span {
      //   margin-left: 10px;
      //   width: 40px;
      //   height: 40px;
      //   border-radius: 50%;
      //   background-color: #fff;
      // }
    }
  }
  .provider {
    padding:0 10px;
    z-index: 99;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 99%;
    height: 30px;
    line-height: 30px;
    border-top: 1px solid #888;
    background-color: #444;
    color: #ccc;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
</style>

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import vueConfig from '../vue.config'
import Vant from 'vant'
import 'vant/lib/index.css'
import { Lazyload } from 'vant'

Vue.use(Vant)
Vue.use(Lazyload)
import '@/assets/css/reset.css'
import '@/assets/icon/iconfont.css'

Vue.config.productionTip = false

// 租户id
Vue.prototype.$tenantId = '501654' // 武侯区为501654
//解决弹窗导致页面抖动
ElementUI.Dialog.props.lockScroll.default = false;
Vue.use(ElementUI)

new Vue({
	router,
	store,
	render: (h) => h(App),
}).$mount('#app')

<template>
  <div @click="handleToTop" :class="isShow ? 'totop-root' : 'totop-root none'">
    <i class="el-icon-arrow-up"></i>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isShow: false,
    };
  },
  methods: {
    handleToTop() {
      let scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      let timer = setInterval(() => {
        scrollTop = scrollTop - 50;
        document.documentElement.scrollTop = document.body.scrollTop = scrollTop;
        if (scrollTop <= 0) {
          clearInterval(timer);
        }
      }, 10);
    },
    handleScroll() {
      let scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      if (scrollTop > 500) {
        this.isShow = true;
      } else {
        this.isShow = false;
      }
    },
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
};
</script>

<style lang="less" scoped>
.totop-root {
  display: inline-block;
  position: fixed;
  z-index: 999;
  bottom: 50px;
  right: 50px;
  width: 40px;
  line-height: 40px;
  border-radius: 20px;
  text-align: center;
  background-color: #7ad3f3;
  cursor: pointer;
  color: #fff;
  .el-icon-caret-top {
    font-size: 18px;
  }
  &.none {
    display: none;
  }
}
</style>
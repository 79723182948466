// 引入封装的第一层axios接口
import req from '@/utils/req.js'
import qs from 'qs'
// 通过账号密码登录 || 刷新token
export const loginByUsername_api = (params) => {
	return req.post('api/blade-auth/oauth/token', params)
}
// 获取动态验证码
export const getCaptcha_api = (params) => {
	return req.get('api/blade-auth/oauth/captcha', params)
}
// 获取手机验证码
export const getPhoneCode_api = (params) => {
	return req.post('api/blade-resource/client/send-validate-no-login', params)
}
// 手机号重置密码
export const updateUserPassWordByPhone_api = (params) => {
	return req.post('api/blade-user/client/updateUserPassWordByPhone', params)
}

// 获取用户详情
export const getAccInfo_api = (params) => {
	return req.get('api/blade-user/portalUser/getInfo', params)
}
//获取学生信息
export const getStudentInfo_api = (params) => {
	return req.get(
		'api/resource-manager/studentauthenticate/getStudentInfoVOById',
		params
	)
}
//更新学生信息
export const updateStudentInfo_api = (params) => {
	return req.post(
		'api/resource-manager/studentauthenticate/updateStudentInfo',
		params
	)
}
// 修改密码
export const updatePwd_api = (params) => {
	return req.post('api/blade-user/portalUser/update-password-user', params)
}
// 绑定微信
export const getWeChatCode_api = (params) => {
	return req.get('api/resource-mobile/getWeChatCodeWithUserId', params)
}
// 微信登录二维码
export const getLoginWeChatCode_api = (params) => {
	return req.get(
		'api/resource-mobile/client/getWeChatCodeWithoutUserId',
		params
	)
}
// 更新头像
export const updateAva_api = (params) => {
	return req.post('api/blade-user/portalUser/update-info-user', params)
}
// 获取学生评价信息
export const getStuEval_api = (params) => {
	return req.get('api/resource-manager/portalManager/getEvaNumView', params)
}
// 获取学生学习数据
export const getStuCour_api = (params) => {
	return req.get(
		'api/resource-manager/portalManager/getFinishedLearningRecordList',
		params
	)
}
// 学生是否优秀
export const isExcellent_api = (params) => {
	return req.get(
		'api/resource-manager/portalManager/isSelectionCredit',
		params
	)
}
// 获取已经预约的课程
export const appointmentCourse_api = (params) => {
	return req.get('api/resource-select/portalSelect/list', params)
}
// 获取雷达图数据
export const getLabelCount_api = (params) => {
	return req.get(
		'api/resource-manager/portalManager/getCourseLabelCountByStudentId',
		params
	)
}
// 获取通知消息
export const portalManager_api = (params) => {
	return req.get(
		'api/resource-manager/portalManager/getMessageListByUserId',
		params
	)
}
// 获取预约记录
export const getReserveBill_api = (params) => {
	return req.get('api/resource-select/portalSelect/getReserveBill', params)
}
// 获取候补记录
export const getAlternateBill_api = (params) => {
	return req.get('api/resource-select/portalSelect/getAlternateBill', params)
}
// 取消预约
export const removeReservecourse_api = (params) => {
	return req.post('api/resource-select/reservecourse/remove', params)
}
// 取消候补
export const removeAlternateBill_api = (params) => {
	return req.post('api/resource-select/reservealternate/remove', params)
}
// 获取教师对学生的评价
export const getEvaluationStudentList_api = (params) => {
	return req.get(
		'api/resource-manager/portalManager/getEvaluationStudentList',
		params
	)
}
// 获取学生对教师的评价
export const getEvaluationTeacherList_api = (params) => {
	return req.get(
		'api/resource-manager/portalManager/getEvaluationTeacherList',
		params
	)
}
// 更新学生对教师的评价
export const updateEvaluationTeacher_api = (params) => {
	return req.post(
		'api/resource-manager/portalManager/updateEvaluationTeacher',
		params
	)
}
// 获取学生对课程的评价
export const getEvaluationCourseList_api = (params) => {
	return req.get(
		'api/resource-manager/portalManager/getEvaluationCourseList',
		params
	)
}
// 更新学生对课程的评价
export const updateEvaluationCourse_api = (params) => {
	return req.post(
		'api/resource-manager/portalManager/updateEvaluationCourse',
		params
	)
}
// 获取教师对学生的列表评价 （教师登录后）
export const teacherGetEvaluationStudentList_api = (params) => {
	return req.get(
		'api/resource-manager/portalManagerForTeacher/getEvaluationStudentList',
		params
	)
}
// 获取学生对教师的列表评价 （教师登录后）
export const teacherGetEvaluationTeacherList_api = (params) => {
	return req.get(
		'api/resource-manager/portalManagerForTeacher/getEvaluationTeacherList',
		params
	)
}
// 更新教师对学生的评价（教师登录后）
export const updateEvaluationStudent_api = (params) => {
	return req.post(
		'api/resource-manager/portalManagerForTeacher/updateEvaluationStudent',
		params
	)
}

// 获取教师的已授课统计 （教师登录后）
export const countFinishedTeachRecord_api = (params) => {
	return req.get(
		'api/resource-manager/portalManagerForTeacher/countFinishedTeachRecord',
		params
	)
}
// 获取教师的团队预约记录 （教师登录后）
export const getReservegroupList_api = (params) => {
	return req.get('api/resource-select/reservegroup/page', params)
}
// 获取教师的授课列表 （教师登录后）
export const getTeacherTeachRecordList_api = (params) => {
	return req.get(
		'api/resource-manager/portalManagerForTeacher/getTeacherTeachRecordList',
		params
	)
}
// 获取教师发布课程列表 （教师登录后）
export const releaseCourseList_api = (params) => {
	return req.get('api/resource-manager/courseinfo/pageNew', params)
}
// 获取上课教师的列表 （教师登录后发布课程）
export const teacherList_api = (params) => {
	return req.get('api/resource-manager/teacherinfo/select', params)
}
// 获取场景列表 （教师登录后发布课程）
export const courseSiteList_api = (params) => {
	return req.get('api/resource-manager/coursesite/select', params)
}
// 获取课程分类列表 （教师登录后发布课程）
export const courseDictionaryList_api = (params) => {
	return req.get(
		'api/blade-system/dict-biz/dictionary?code=course_sorts',
		params
	)
}
// 发布课程 （教师登录后发布课程）
export const addCourse_api = (params) => {
	return req.jsonPost('api/resource-manager/courseinfo/save', params, {
		isJson: true,
	})
}
// 发布课程查看已预约学生名单 （教师登录后发布课程列表）
export const getCourseStudents_api = (params) => {
	return req.get('api/resource-select/reservecourse/studentList', params)
}
// 发布课程修改课程 （教师登录后发布课程列表）
export const updateCourse_api = (params) => {
	return req.jsonPost('api/resource-manager/courseinfo/update', params, {
		isJson: true,
	})
}
// 发布课程复制课程 （教师登录后发布课程列表）
export const copyCourse_api = (params) => {
	return req.post('api/resource-manager/courseinfo/copy', params)
}
// 发布课程提交审核 （教师登录后发布课程列表）
export const reviewCourse_api = (params) => {
	return req.get('api/resource-manager/courseinfo/review', params)
}
// 发布课程删除课程 （教师登录后发布课程列表）
export const removeCourse_api = (params) => {
	return req.post('api/resource-manager/courseinfo/remove', params)
}
// 发布课程课程下线 （教师登录后发布课程列表）
export const offlineCourse_api = (params) => {
	return req.get('api/resource-manager/courseinfo/offline', params)
}
//问卷调查提交
export const saveQuestion_api = (params) => {
	return req.jsonPost(
		'api/resource-manager/resourcequestionnaireanswer/save',
		params,
		{ isJson: true }
	)
}

// 课程 立即预约
export const reserveCourse_api = (params) => {
	return req.post('api/resource-select/reservecourse/save', params)
}
// 课程 立即候补
export const reservealternate_api = (params) => {
	return req.post('api/resource-select/reservealternate/save', params)
}
//问卷调查详情
export const getQuestion_api = () => {
	return req.get(
		'api/resource-manager/resourcequestionnairequestion/getQuestionMap'
	)
}
//微信登录检查
export const weChatCheckLogin_api = (params) => {
	return req.get('api/resource-mobile/client/weChatCheckLogin', params)
}
//获取场景机构
export const getDeptList_api = () => {
	return req.get('api/resource-count/attendanceCount/getDeptList')
}
//保存建议
export const saveAdvice_api = (params) => {
	return req.jsonPost(
		'api/resource-manager/resourceadviceinfo/save',
		params,
		{ isJson: true }
	)
}
//短信发送
export const sendValidate_api = (params) => {
	return req.get('api/blade-resource/client/send-validate-register', params)
}
//短信校验
export const validateMessage_api = (params) => {
	return req.post(
		'api/blade-resource/client/validate-message-no-code',
		params
	)
}
//账号验证
export const validateUserByPhone_api = (params) => {
	return req.get('api/resource-manager/client/register/if-exist-user', params)
}
//注册里获取学校
export const registerSchool_api = (params) => {
	return req.get('api/blade-system/client/select', params)
}
//注册里获取年级
export const registerGrade_api = (params) => {
	return req.get('api/blade-system/client/dict-biz/get-list', params)
}
//注册信息提交
export const registerSubmit_api = (params) => {
	return req.post('api/resource-manager/client/register/use-info', params)
}
//志愿者信息提交保存
export const volunteerSubmit_api = (params) => {
	return req.jsonPost(
		'api/resource-manager/client/volunteer-info/save',
		params,
		{ isJson: true }
	)
}
//志愿者获取学历列表
export const getEducation_api = () => {
	return req.get('api/blade-system/client/dict/get-list?code=edu_level')
}
//志愿者获取已开课场景（不需要权限）
export const getScenceList_api = () => {
	return req.get('api/blade-system/client/dept/has-course')
}
//志愿者服务记录
export const volunteerServeList_api = (params) => {
	return req.get(
		'api/resource-manager/volunteerworkingbill/singleList',
		params
	)
}
//荣誉证书信息
export const volunteerWorkingBillCount_api = (params) => {
	return req.get(
		'api/resource-manager/volunteerworkingbill/volunteerWorkingBillCount',
		params
	)
}

// 封装axios 第一层封装
// 引入axios和qs
import axios from 'axios'
import qs from 'qs'
import { Message } from 'element-ui'
// 引入路由
import router from '@/router'
import store from '@/store'

// 工具js
import { sessionGet, clear, sessionClear } from '@/utils/local.js'

// 引入路由
// import router from "@/router"
// http request拦截器 添加一个请求拦截器
axios.interceptors.request.use((config) => {
	let token = sessionGet('tk')
	if (token) {
		if (config.isJson) {
			config.headers = {
				Authorization: 'Basic cG9ydGFsOnBvcnRhbF9zZWNyZXQ=', // 网站标识‘portal’的base64编码后字符串
				'Tenant-Id': '501654',
				'Content-type': 'application/json',
				'Blade-Auth': token.token_type + ' ' + token.access_token,
			}
		} else {
			config.headers = {
				Authorization: 'Basic cG9ydGFsOnBvcnRhbF9zZWNyZXQ=', // 网站标识‘portal’的base64编码后字符串
				'Tenant-Id': '501654',
				'Content-type': 'application/x-www-form-urlencoded',
				'Blade-Auth': token.token_type + ' ' + token.access_token,
			}
		}
	} else {
		if (config.isJson) {
			config.headers = {
				Authorization: 'Basic cG9ydGFsOnBvcnRhbF9zZWNyZXQ=', // 网站标识‘portal’的base64编码后字符串
				'Tenant-Id': '501654',
				'Content-type': 'application/json',
			}
		} else {
			config.headers = {
				Authorization: 'Basic cG9ydGFsOnBvcnRhbF9zZWNyZXQ=', // 网站标识‘portal’的base64编码后字符串
				'Tenant-Id': '501654',
				'Content-type': 'application/x-www-form-urlencoded',
			}
		}
	}
	return config
})

//  响应拦截器  响应后，统一做一些事情
axios.interceptors.response.use(
	(res) => {
		return res
	},
	(error) => {
		// 失败的回调  无效的token(路由守卫)
		if (error.response.status == 401) {
			Message.error('您的权限已失效，请重新登录')
			router.push('/login')
			store.commit('refresh_token', false)
            sessionClear()
            clear()
            store.commit('resetState')
		} else if (error.response.status == 404) {
			Message.error('网页丢失了')
			router.push('/error404')
		} else if (error.response.status != 200) {
			Message.error(error.response.data.error_description)
		}
	}
)

export default {
	get(url, params) {
		return new Promise((resolve, reject) => {
			axios
				.get(url, { params })
				.then((res) => {
					resolve(res.data)
				})
				.catch((error) => {
					reject(error)
				})
		})
	},
	post(url, params) {
		return new Promise((resolve, reject) => {
			axios
				.post(url, qs.stringify(params))
				.then((res) => {
					resolve(res.data)
				})
				.catch((error) => {
					reject(error)
				})
		})
	},
	jsonPost(url, params, isJson = { isJson: false }) {
		return new Promise((resolve, reject) => {
			axios
				.post(url, params, isJson)
				.then((res) => {
					resolve(res.data)
				})
				.catch((error) => {
					reject(error)
				})
		})
	},
}
// 'api/resource-manager/resourcequestionnaireanswer/save'

<template>
	<div id="app" class="app">
		<div v-if="createdPage" class="appRoot">
			<router-view v-if="isRouterAlive"></router-view>
		</div>
	</div>
</template>

<script>
import { loginByUsername_api } from '@/api/user.js'
import { sessionGet, sessionSet } from '@/utils/local.js'
export default {
	name: 'App',
	provide() {
		return {
			reload: this.reload,
		}
	},
	data() {
		return {
			timer: '',
			createdPage: true, // 默认可以直接创建节点。登录时改为false，需要判断有效的token是否保存好(处理刷新页面时获取新token)
			isRouterAlive: true,
		}
	},
	computed: {
		isRefresh_token() {
			return this.$store.state.isRefresh_token
		},
	},
	methods: {
		// 重载页面
		reload() {
			this.isRouterAlive = false
			this.$nextTick(() => {
				this.isRouterAlive = true
			})
		},
		// 获取新的token
		async getToken() {
			let res = await loginByUsername_api({
				tenantId: this.$tenantId,
				refresh_token: sessionGet('tk').refresh_token,
				grant_type: 'refresh_token',
				scope: 'all',
			})
			let token = {
				access_token: res.access_token,
				refresh_token: res.refresh_token,
				token_type: res.token_type,
			}
			sessionSet('tk', token)
			// token获取成功后在创建节点
			this.createdPage = true
		},
		// 定时30分钟前10秒刷新token
		refresh_token() {
			this.timer = setInterval(this.getToken, 1790000)
		},
	},
	watch: {
		isRefresh_token(val) {
			clearInterval(this.timer) // 先清除定时器
			if (val) {
				// 登录时设置定时器
				this.refresh_token()
			}
		},
	},
	created() {
		//在页面加载时读取sessionStorage里的状态信息
		if (sessionGet('store')) {
			this.$store.replaceState(
				Object.assign({}, this.$store.state, sessionGet('store'))
			)
		}
		// 加载页面的时候判断是否登录，
		if (this.$store.state.isLogin && sessionGet('tk')) {
			// 刷新token时先不创建节点，等待获取新的token
			this.createdPage = false
			// 立即刷新一次token并重新设置定时器
			this.getToken()
			this.refresh_token()
		}
		//在页面刷新时将vuex里的信息保存到sessionStorage里
		window.addEventListener('beforeunload', () => {
			sessionSet('store', this.$store.state)
		})
	},
}
</script>

<style lang="less">
#app {
	background-color: #fff;
	height: 100%;
	.appRoot {
		height: 100%;
	}
}
</style>

<template>
	<div class="root">
		<div :style="biStyle"></div>
		<div class="containar">
			<LeftMenu />
			<div class="right-main">
				<div class="top-hellow">
					您好，{{
						$store.state.userInfo.realName +
							($store.state.userInfo.roleName === '学生'
								? '同学'
								: $store.state.userInfo.roleName === 'student'
								? '同学'
								: $store.state.userInfo.roleName === '教师'
								? '老师'
								: $store.state.userInfo.roleName === 'teacher'
								? '老师'
								: '志愿者')
					}}
				</div>
				<div class="main">
					<router-view></router-view>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import LeftMenu from '@/views/user/components/leftMenu.vue'
import bc from '@/assets/img/sceneInfoBc.jpg'
export default {
	components: {
		LeftMenu,
	},
	data() {
		return {
			biStyle: {
				// 导航栏下面的通栏背景图
				height: '280px',
				backgroundImage: 'url(' + bc + ')',
			},
		}
	},
	methods: {},
	created() {},
}
</script>

<style lang="less" scoped>
@title-color: #0079fe;
@btn-color: #f66866;
.containar {
	margin-top: -280px;
	display: flex;
	.right-main {
		flex: 1;
		.top-hellow {
			margin: 40px 0;
			color: #fff;
			font-weight: 700;
		}
		.main {
			background-color: #fff;
		}
	}
}
</style>

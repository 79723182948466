<template>
	<div class="root">
		<el-image :src="adviceUrl" class="advice-img" alt="加载失败"></el-image>
		<div class="advice" @click="adviceShow">提个建议</div>
		<el-dialog
			title="建议"
			:visible.sync="dialogVisible"
			width="30%"
			:modal-append-to-body="false"
			><!-- 遮盖层覆盖住dialog,使用modal-append-to-body属性-->
			<div>
				<el-form
					ref="form"
					:model="form"
					:rules="rules"
					label-width="100px"
				>
					<el-form-item label="学校机构：">
						<el-checkbox-group
							v-model="form.checkedDept"
							@change="handleCheckedDeptChange"
						>
							<el-checkbox
								v-for="item in form.deptList"
								:label="item.name + '=' + item.id"
								:key="item.id"
								>{{ item.name }}</el-checkbox
							>
						</el-checkbox-group>
					</el-form-item>
					<el-form-item label="建议：" prop="desc">
						<el-input
							type="textarea"
							v-model="form.desc"
							:rows="5"
						></el-input>
					</el-form-item>
					<!-- <el-form-item>
						<el-button
							type="primary"
							@click="onSubmit('form')"
							:disabled="isAble == -1"
							>立即提交</el-button
						>
					</el-form-item> -->
				</el-form>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="onSubmit('form')"
					>立即提交</el-button
				>
			</span>
		</el-dialog>
	</div>
</template>

<script>
import { getDeptList_api, saveAdvice_api } from '@/api/user.js'
import advice from '@/assets/advice.gif'
export default {
	inject: ['reload'],
	data() {
		return {
			adviceUrl: advice,
			dialogVisible: false,
			isAble: 'false',
			form: {
				deptList: [],
				checkedDept: [],
				desc: '',
			},
			rules: {
				desc: [
					{
						required: true,
						message: '请填写建议内容',
						trigger: 'blur',
						max: 300,
					},
				],
			},
		}
	},
	methods: {
		adviceShow() {
			if (!this.$store.state.isLogin) {
				// 如果没登录
				this.$message.error('请先登录！')
			} else {
				this.dialogVisible = true
			}
		},
        //获取机构列表
		async getDeptList() {
			let res = await getDeptList_api({})

			if (res.code == 200) {
				if (res.data.length > 0) {
					this.form.deptList = res.data
				}
			}
		},
		handleCheckedDeptChange(val) {
			// console.log(JSON.stringify(val))
		},
        //提交建议表单
		async onSubmit(formName) {
			this.isAble = '-1'
			let arr = this.form.checkedDept
			let deptId = ''
			let deptName = ''
			//let {flag}=await  this.checkFormFlag(formName,flag)
			this.$refs[formName].validate((valid) => {
				if (valid) {
					for (let i = 0; i < arr.length; i++) {
						let arrStr = arr[i].split('=')
						if (i < arr.length - 1) {
							deptName += arrStr[0] + ','
							deptId += arrStr[1] + ','
						} else {
							deptName += arrStr[0]
							deptId += arrStr[1]
						}
					}
					let params = {
						deptId: '',
						deptName: '',
						content: '',
					}
					params.deptId = deptId
					params.deptName = deptName
					params.content = this.form.desc

					saveAdvice_api(params).then(
						(res) => {
							this.$message({
								type: 'info',
								message: `提交成功`,
							})
							this.dialogVisible = false
							this.reload()
						},
						(error) => {
							this.$message({
								type: 'info',
								message: `提交失败`,
							})
						}
					)
				}
				return false
			})

			this.isAble = 'false'
		},
	},
	created() {
		this.getDeptList()
	},
}
</script>

<style lang="less" scoped>
.root {
	display: inline-block;
	position: fixed;
	z-index: 999;
	bottom: 250px;
	right: 0px;
	width: 50px;
	.advice {
		padding: 6px;
		font-size: 0.5em;
		line-height: 25px;
		border-radius: 2px;
		text-align: center;
		background-color: #7ad3f3;
		cursor: pointer;
		color: #fff;
	}
}
</style>

import Vue from 'vue'
import store from '@/store/index.js'
import VueRouter from 'vue-router'

import Layout from '@/views/layout/Layout.vue'

import { sessionGet } from '@/utils/local.js'

import user from './user.js'
import mobile from './mobile.js'

Vue.use(VueRouter)

// 公共路由
const commonRoutes = [
	{
		path: '/',
		name: 'layout',
		redirect: '/index',
	},
	{
		path: '/',
		name: 'layout',
		component: () => import('@/views/layout/Layout.vue'),
	},
	{
		path: '/login',
		name: '登录',
		component: () => import('@/views/login/login.vue'),
	},
	{
		path: '/register',
		name: '注册',
		component: () => import('@/views/login/register.vue'),
	},
	{
		path: '/error404',
		name: 'error404',
		component: () => import('@/views/error/error404.vue'),
	},
	{
		path: '/index',
		name: 'homepage',
		component: Layout,
		redirect: '/index/index',
		children: [
			{
				path: '/index/index',
				name: '首页',
				component: () => import('@/views/index/Index.vue'),
			},
		],
	},
	{
		path: '/course',
		name: 'course',
		component: Layout,
		redirect: '/course/index',
		children: [
			{
				path: '/course/index',
				name: '课程信息',
				component: () => import('@/views/course/Course.vue'),
			},
			{
				path: '/course/courseInfo',
				name: '课程详情',
				component: () => import('@/views/course/courseInfo.vue'),
			},
			{
				path: '/course/courseAdd',
				name: '课程预约',
				component: () => import('@/views/course/courseAdd.vue'),
			},
		],
	},
	{
		path: '/notice',
		name: 'notice',
		component: Layout,
		redirect: '/notice/index',
		children: [
			{
				path: '/notice/index',
				name: '通知公告',
				component: () => import('@/views/notice/Notice.vue'),
			},
			{
				path: '/notice/noticeInfo',
				name: '公告详情',
				component: () => import('@/views/notice/noticeInfo.vue'),
			},
		],
	},
	{
		path: '/scene',
		name: 'scene',
		component: Layout,
		redirect: '/scene/index',
		children: [
			{
				path: '/scene/index',
				name: '场景概览',
				component: () => import('@/views/scene/Scene.vue'),
			},
			{
				path: '/scene/sceneInfo',
				name: '场景详情',
				component: () => import('@/views/scene/sceneInfo.vue'),
			},
			{
				path: '/scene/sceneAdd',
				name: '场景预约',
				component: () => import('@/views/scene/sceneAdd.vue'),
			},
		],
	},
	{
		path: '/team',
		name: 'team',
		component: Layout,
		redirect: '/team/index',
		children: [
			{
				path: '/team/index',
				name: '团队预约',
				component: () => import('@/views/team/Team.vue'),
			},
		],
	},
/*	{
		path: '/volunteer',
		name: 'volunteer',
		component: Layout,
		redirect: '/volunteer/index',
		children: [
			{
				path: '/volunteer/index',
				name: '志愿者招募',
				component: () => import('@/views/volunteer/Volunteer.vue'),
			},
			{
				path: '/volunteer/certificate',
				name: '志愿者荣誉证书',
				component: () =>
					import('@/views/volunteer/VolunteerCertificate.vue'),
			},
		],
	},*/
	{
		path: '/data',
		name: '数据大屏',
		component: () => import('@/views/data/data.vue'),
	}
]
// 需要动态添加的路由
export const allRoutes = [user]

const router = new VueRouter({
	routes: [...commonRoutes, user, mobile],
})

// 路由守卫
router.beforeEach((to, from, next) => {
	if (process.env.NODE_ENV === 'production') {
		// 生产环境
		document.title = '武侯乐学通'
	} else {
		document.title = '武侯乐学通（测试）'
	}
	// 判断设备
	let flag = navigator.userAgent.match(
		/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
	)
	// 获取本地的token判断有没有,判断是否登录
	let isLogin = sessionGet('tk') ? true : false
	let role_code
	if (sessionGet('userInfo')) {
		role_code = sessionGet('userInfo').role_code
	}
	if (flag) {
		if (!to.path.includes('/mobile')) {
			router.push('/mobile')
		} else {
			// 数据需登录后再看
			if (to.path.includes('/mobile/data')) {
				if (isLogin && role_code == 'districtUser') {
					next()
				} else {
					// 没有登录或者没有权限就返回到登录
					router.push('/mobile/login')
				}
			} else {
				next()
			}
		}
	} else {
		if (to.path.includes('/user')) {
			// 去个人中心登录后就可以访问
			if (isLogin) {
				next()
			} else {
				// 没有就返回到登录
				router.push('/login')
			}
		} else if (to.path.includes('/data')) {
			if (isLogin && role_code == 'districtUser') {
				next()
			} else {
				// 没有登录或者没有权限就返回到登录
				router.push('/login')
			}
		} else {
			next()
		}
	}
})

// 解决两次访问相同路由地址报错
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch((err) => err)
}

export default router

<template>
  <div class="mobile">
    <!-- <div class="head">武侯乐学通</div> -->
    <div class="router"><router-view></router-view></div>
    <div class="nav">
      <div
        v-for="(item, index) in nav"
        @click="handleChangeNav(item)"
        :key="index"
        :class="activeNav.hash == item.hash ? 'nav-item activeNav' : 'nav-item'"
      >
        <span>{{ item.title }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { sessionSet, sessionGet } from "@/utils/local.js";
export default {
  data() {
    return {
      activeNav: {},
      nav: [
        { title: "首页", icon: "", hash: "/mobile/index" },
        { title: "课程", icon: "", hash: "/mobile/course" },
        { title: "场景", icon: "", hash: "/mobile/scene" },
        { title: "数据", icon: "", hash: "/mobile/data" },
      ],
    };
  },
  methods: {
    // 切换导航
    handleChangeNav(item) {
      if (item.hash == this.activeNav.hash) return;
      this.activeNav = { ...item };
      this.$router.push(item.hash);
    },
  },
  watch: {
    $route(to, from) {
      // 进入新页面的时候回到顶部
      // document.documentElement.scrollTop = document.body.scrollTop = 0;
      // 判断该激活哪个导航
      this.nav.forEach((item) => {
        if (to.path.includes(item.hash)) this.activeNav = { ...item };
      });
    },
  },
  created() {
    this.activeNav = sessionGet("mobileActiveNav") || {
      title: "首页",
      icon: "",
      hash: "/mobile/index",
    };
    //在页面刷新时
    window.addEventListener("beforeunload", () => {
      sessionSet("mobileActiveNav", this.activeNav);
    });
  },
};
</script>

<style lang="less" scoped>
.mobile {
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #f4f4f4;
  // .head {
  //   width: 100vw;
  //   line-height: 40px;
  //   text-align: center;
  //   background-color: #fee6ca;
  //   color: #f60;
  //   font-weight: 700;
  // }
  .nav {
    line-height: 50px;
    display: flex;
    .nav-item {
      flex: 1;
      text-align: center;
      background-color: #fee6ca;
      color: #999;
      &.activeNav {
        color: #f60;
      }
    }
  }
  .router {
    flex: 1;
    overflow-y: scroll;
    /*隐藏滚动条，当IE下溢出，仍然可以滚动*/
    -ms-overflow-style: none;
    /*火狐下隐藏滚动条*/
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
    /*解决ios上滑动不流畅*/
    -webkit-overflow-scrolling: touch;
  }
}
</style>
import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)
export default new Vuex.Store({
	state: {
		userInfo: {}, // 登录用户信息
		isRefresh_token: false, // 是否设置定时刷新token
		routes: [], // 当前角色可访问的路由数组
		isLogin: false, // 是否登录
		reserveCourseRes: {}, // 学生预约课程成功后返回的信息
		courseInfo: {}, // 课程详情
		course: {}, // 教师发布的课程信息(用于修改课程)
		siteSlideshow: [], // 场景列表页轮播数据
		siteCourse: [], //移动端场景详情的课程
		courseLabelDict: [], //课程标签字典
		courseStateDict: [], //课程状态字典
		registerPhone: '', //注册时的手机号码
		volunteerInfo: {}, //申请志愿者成功后志愿者信息
	},
	mutations: {
		// 重置state的用户信息数据
		resetState(state) {
			state.userInfo = {}
			state.routes = []
			state.isLogin = false
		},
		// 更新用户信息
		updateUserInfo(state, userInfo) {
			state.userInfo = userInfo
		},
		// 更新可访问的路由
		updateRoutes(state, routes) {
			state.routes = routes
		},
		// 更新登录状态
		updateLogin(state, bool) {
			state.isLogin = bool
		},
		// 更新学生预约课程成功后返回的信息
		updateReserveCourseRes(state, data) {
			state.reserveCourseRes = data
		},
		// 更新课程详情
		updateCourseInfo(state, data) {
			state.courseInfo = data
		},
		// 更新教师发布的课程信息
		updateCourse(state, data) {
			state.course = data
		},
		// 定时刷新token
		refresh_token(state, bool) {
			state.isRefresh_token = bool
		},
		updateSiteSlideshow(state, arr) {
			state.siteSlideshow = arr
		},
		//更新场景详情里的课程列表
		updateSiteCourse(state, data) {
			state.siteCourse = data
		},
		//课程标签字典
		updateCourseDict(state, data) {
			state.courseLabelDict = [].concat(data)
		},
		//课程状态字典
		updateCourseStateDict(state, data) {
			state.courseStateDict = [].concat(data)
		},
		//注册时的手机号码
		updateRegisterPhone(state, data) {
			state.registerPhone = data
		},
		//志愿者申请成功后的志愿者信息
		updateVolunteerInfo(state, data) {
			state.volunteerInfo = data
		},
	},
	actions: {},
	modules: {},
})

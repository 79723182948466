var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header-root"},[_c('div',{staticClass:"main"},[_c('div',{staticClass:"logoBox"},[_c('el-image',{staticClass:"logo",attrs:{"src":_vm.logo,"fit":"cover","alt":"加载失败"}})],1),_c('div',{staticClass:"nav-box"},[_c('div',{staticClass:"nav"},_vm._l((_vm.navArr),function(item,i){return _c('router-link',{key:i,class:_vm.activeIndex == item.path
								? 'setting-item active'
								: 'setting-item',attrs:{"tag":"div","to":item.path}},[_c('el-image',{staticStyle:{"width":"30px","height":"30px"},attrs:{"src":_vm.activeIndex == item.path ? item.url2 : item.url1,"alt":"加载失败","fit":"cover"}}),_c('span',[_vm._v(_vm._s(item.title))])],1)}),1),_c('div',{staticClass:"loginBox"},[(!_vm.$store.state.isLogin)?_c('span',{staticClass:"goLogin",on:{"click":function($event){return _vm.$router.push(
								`/login?redirect=${_vm.$router.currentRoute.fullPath}`
							)}}},[_vm._v("登录")]):_c('div',[_c('el-dropdown',[_c('div',{staticClass:"el-dropdown-link"},[_c('span',[_vm._v(_vm._s(_vm.$store.state.userInfo.real_name || _vm.$store.state.userInfo.realName))]),(
						_vm.$store.state.userInfo.roleCode == 'student' ||
							_vm.$store.state.userInfo.roleName == '学生'
					)?_c('div',[(
							_vm.$store.state.userInfo.sexName == '女' ||
								_vm.$store.state.userInfo.sex == 0
						)?_c('el-image',{attrs:{"src":_vm.$store.state.userInfo.avatar || _vm.avatarGirl,"fit":"cover","alt":"加载失败"}}):_c('el-image',{attrs:{"src":_vm.$store.state.userInfo.avatar || _vm.avatarBoy,"fit":"cover","alt":"加载失败"}})],1):_c('div',[(
							_vm.$store.state.userInfo.sexName == '女' ||
								_vm.$store.state.userInfo.sex == 0
						)?_c('el-image',{attrs:{"src":_vm.$store.state.userInfo.avatar || _vm.avatarWoman,"fit":"cover","alt":"加载失败"}}):_c('el-image',{attrs:{"src":_vm.$store.state.userInfo.avatar || _vm.avatarMan,"fit":"cover","alt":"加载失败"}})],1)]),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},[(
										_vm.$store.state.userInfo.roleCode ==
											'districtUser'
									)?_c('el-dropdown-item',{attrs:{"icon":"el-icon-s-platform"},nativeOn:{"click":function($event){return _vm.$router.push('/data')}}},[_vm._v("数据中心")]):_c('el-dropdown-item',{attrs:{"icon":"el-icon-s-custom"},nativeOn:{"click":function($event){return _vm.$router.push('/user')}}},[_vm._v("个人中心")]),_c('el-dropdown-item',{attrs:{"icon":"el-icon-s-promotion"},nativeOn:{"click":function($event){return _vm.handleOut.apply(null, arguments)}}},[_vm._v("退出登录")])],1)],1)],1)])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }
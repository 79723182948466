var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"left-nav"},[_c('div',{staticClass:"user"},[(
					_vm.$store.state.userInfo.roleCode == 'student' ||
						_vm.$store.state.userInfo.roleName == '学生'
				)?_c('div',[(
						_vm.$store.state.userInfo.sexName == '女' ||
							_vm.$store.state.userInfo.sex == 0
					)?_c('el-image',{attrs:{"src":_vm.$store.state.userInfo.avatar || _vm.avatarGirl,"fit":"cover","alt":"加载失败"}}):_c('el-image',{attrs:{"src":_vm.$store.state.userInfo.avatar || _vm.avatarBoy,"fit":"cover","alt":"加载失败"}})],1):_c('div',[(
						_vm.$store.state.userInfo.sexName == '女' ||
							_vm.$store.state.userInfo.sex == 0
					)?_c('el-image',{attrs:{"src":_vm.$store.state.userInfo.avatar || _vm.avatarWoman,"fit":"cover","alt":"加载失败"}}):_c('el-image',{attrs:{"src":_vm.$store.state.userInfo.avatar || _vm.avatarMan,"fit":"cover","alt":"加载失败"}})],1),_c('p',[_vm._v(" "+_vm._s(_vm.$store.state.userInfo.real_name || _vm.$store.state.userInfo.realName)+" "),(
						_vm.$store.state.userInfo.sexName === '女' ||
							_vm.$store.state.userInfo.sex == 0
					)?_c('i',{staticClass:"iconfont icon-229yonghu_xingbienv"}):_c('i',{staticClass:"iconfont icon-228yonghu_xingbienan"})])]),_c('div',{staticClass:"nav"},[_c('el-menu',{staticClass:"el-menu-vertical-demo",attrs:{"default-active":this.$route.path,"router":"","unique-opened":""}},_vm._l((_vm.navArr),function(item,i){return _c('el-submenu',{key:i,attrs:{"index":item.path}},[_c('template',{slot:"title"},[_c('span',[_vm._v(_vm._s(item.name))])]),_c('el-menu-item-group',_vm._l((item.children),function(val,j){return _c('el-menu-item',{key:j,attrs:{"index":val.path}},[_vm._v(_vm._s(val.name))])}),1)],2)}),1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }
// const server = "http://172.16.10.97"; // 王进韬ip
// const server = "http://172.16.10.161"; // 刘星ip
// const server = "http://172.16.10.150:7002"; // 罗ip
const server = "https://edures.lexuestudy.com:8003";
// const server = "http://172.16.10.72";
// const server = "http://172.16.11.49";
// const server = "http://172.16.10.128";
// const server = "http://172.16.10.233:8088";
// const server = "http://172.16.10.140";

module.exports = {
  devServer: {
    port: 9111,
    proxy: {
      "/api": {
        target: server,
        pathRewrite: {
          "^/api": "/",
        },
      },
    },
  },
};

<template>
	<div class="header-root">
		<div class="main">
			<div class="logoBox">
				<el-image class="logo" :src="logo" fit="cover" alt="加载失败"></el-image>
			</div>
			<div class="nav-box">
				<div class="nav">
					<router-link
						v-for="(item, i) in navArr"
						:key="i"
						tag="div"
						:class="
							activeIndex == item.path
								? 'setting-item active'
								: 'setting-item'
						"
						:to="item.path"
					>
						<el-image
							style="width: 30px; height: 30px"
							:src="
								activeIndex == item.path ? item.url2 : item.url1
							"
                            alt="加载失败"
							fit="cover"
						></el-image>
						<span>{{ item.title }}</span>
					</router-link>
				</div>

				<div class="loginBox">
					<span
						v-if="!$store.state.isLogin"
						@click="
							$router.push(
								`/login?redirect=${$router.currentRoute.fullPath}`
							)
						"
						class="goLogin"
						>登录</span
					>
					<!-- <span
						v-if="!$store.state.isLogin"
						class="goRegister"
						@click="$router.push(`/register`)"
						>注册</span
					> -->

					<div v-else>
						<el-dropdown>
							<div class="el-dropdown-link">
								<span>{{
									$store.state.userInfo.real_name ||
										$store.state.userInfo.realName
								}}</span>
								<div
					v-if="
						$store.state.userInfo.roleCode == 'student' ||
							$store.state.userInfo.roleName == '学生'
					"
				>
					<el-image
						v-if="
							$store.state.userInfo.sexName == '女' ||
								$store.state.userInfo.sex == 0
						"
						:src="$store.state.userInfo.avatar || avatarGirl"
						fit="cover"
						alt="加载失败"
					></el-image>
					<el-image
						v-else
						:src="$store.state.userInfo.avatar || avatarBoy"
						fit="cover"
						alt="加载失败"
					></el-image>
				</div>
				<div v-else>
					<el-image
						v-if="
							$store.state.userInfo.sexName == '女' ||
								$store.state.userInfo.sex == 0
						"
						:src="$store.state.userInfo.avatar || avatarWoman"
						fit="cover"
						alt="加载失败"
					></el-image>
                    <el-image
						v-else
						:src="$store.state.userInfo.avatar || avatarMan"
						fit="cover"
						alt="加载失败"
					></el-image>
				</div>
							</div>
							<el-dropdown-menu slot="dropdown">
								<el-dropdown-item
									v-if="
										$store.state.userInfo.roleCode ==
											'districtUser'
									"
									@click.native="$router.push('/data')"
									icon="el-icon-s-platform"
									>数据中心</el-dropdown-item
								>
								<el-dropdown-item
									v-else
									@click.native="$router.push('/user')"
									icon="el-icon-s-custom"
									>个人中心</el-dropdown-item
								>
								<el-dropdown-item
									@click.native="handleOut"
									icon="el-icon-s-promotion"
									>退出登录</el-dropdown-item
								>
							</el-dropdown-menu>
						</el-dropdown>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import logo from '@/assets/img/logo2.svg'
import avatarMan from '@/assets/img/avatar_01.gif'
import avatarWoman from '@/assets/img/avatar_02.gif'
import avatarBoy from '@/assets/img/avatar_03.gif'
import avatarGirl from '@/assets/img/avatar_04.gif'
import { clear, sessionClear } from '@/utils/local.js'
export default {
	data() {
		return {
			logo,
			//用户默认头像
            avatarBoy,
			avatarGirl,
			avatarMan,
			avatarWoman,
			activeIndex: '', // 激活导航
			// 头部导航栏数组
			navArr: [
				{
					title: '首页',
					path: '/index/index',
					url1: require('@/assets/img/index1.png'),
					url2: require('@/assets/img/index2.png'),
				},
				{
					title: '课程信息',
					path: '/course',
					url1: require('@/assets/img/course1.png'),
					url2: require('@/assets/img/course2.png'),
				},
				{
					title: '通知公告',
					path: '/notice',
					url1: require('@/assets/img/notice1.png'),
					url2: require('@/assets/img/notice2.png'),
				},
				{
					title: '场景概览',
					path: '/scene',
					url1: require('@/assets/img/scene1.png'),
					url2: require('@/assets/img/scene2.png'),
				},
        /*	{
            title: '团队预约',
            path: '/team',
            url1: require('@/assets/img/team1.png'),
            url2: require('@/assets/img/team2.png'),
          },
          {
            title: '志愿者招募',
            path: '/volunteer',
            url1: require('@/assets/img/volunteer2.svg'),
            url2: require('@/assets/img/volunteer1.svg'),
          },*/
			],
		}
	},
	methods: {
		// 退出登录
		handleOut() {
			this.$store.commit('refresh_token', false)
			sessionClear()
			clear()
			this.$store.commit('resetState')
			if (this.$route.path.includes('/user'))
				this.$router.replace('/index')
			if (this.$route.path.includes('/scene/sceneAdd'))
				this.$router.replace('/scene')
			if (this.$route.path.includes('/course/courseAdd'))
				this.$router.replace('/course')
		},
	},
	created() {
		// 刷新时根据路由判断该激活哪个导航
		this.navArr.forEach((item) => {
			if (this.$route.path.includes(item.path))
				this.activeIndex = item.path
		})
	},
	watch: {
		// 监听路由，
		$route(to, from) {
			// 进入新页面的时候回到顶部
			document.documentElement.scrollTop = document.body.scrollTop = 0
			// 判断该激活哪个导航
			if (to.path.includes('/user')) return (this.activeIndex = '/user')
			this.navArr.forEach((item) => {
				if (to.path.includes(item.path)) this.activeIndex = item.path
			})
		},
	},
}
</script>

<style lang="less" scoped>
@btn-color: #f66866;
@btn-login: rgb(65, 159, 247);
.header-root {
	position: fixed;
	width: 100%;
	height: 80px;
	z-index: 99;
	top: 0;
	left: 0;
	background-color: #fff;
	box-shadow: 0px -10px 10px 1px rgb(0, 0, 0, 0.2);
	.main {
		padding-right: 4vw;
		height: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		user-select: none;
		.logoBox {
			display: flex;
			align-items: center;
			.logo {
				vertical-align: center;
				width: 240px;
				height: 80px;
				// line-height: 40px;
				// color:#000;
				// font-weight:600;
				// span{
				//   margin-right:10px;
				//   &:nth-child(2){
				//     margin-right:5px;
				//   }
				// }
			}
		}
		// .web-title {
		//   font-weight: 700;
		// }
		.nav-box {
			display: flex;
			align-items: center;
			.nav {
				margin: 0 4vw 0 6px;
				display: flex;
				.setting-item {
					display: flex;
					flex-direction: column;
					align-items: center;
					padding: 0 1.4vw;
					cursor: pointer;
					&.active,
					&:hover {
						color: @btn-color;
					}
					span {
						margin-top: 5px;
						letter-spacing: 3px;
						font-size: 16px;
					}
					i {
						font-size: 24px;
					}
				}
			}
			.loginBox {
				display: flex;
				flex-direction: row;
				justify-content: center;
				.goLogin {
                    width: 2rem;
                    height: 1rem;
					padding: 5px 0.8vw;
					border-radius: 5px;
					border: 1px solid @btn-login;
					cursor: pointer;
					font-size: 0.9em;
					color: @btn-login;
					&:hover {
						background-color: @btn-login;
						color: white;
					}
				}
				// .goRegister {
                //     width: 2rem;
                //     height: 1rem;
				// 	padding: 5px 0.8vw;
				// 	border-radius: 5px;
				// 	border: 1px solid @btn-color;
				// 	cursor: pointer;
				// 	font-size: 0.9rem;
				// 	color: @btn-color;
				// 	margin-left: 1vw;
				// 	&:hover {
				// 		background-color: @btn-color;
				// 		color: white;
				// 	}
				// }
			}

			.el-dropdown-link {
				display: flex;
				align-items: center;
				.el-image {
					margin-left: 5px;
					width: 36px;
					height: 36px;
					border-radius: 50%;
				}
			}
		}
	}
}
</style>

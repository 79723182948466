<template>
	<div>
		<div class="left-nav">
			<div class="user">
				<div
					v-if="
						$store.state.userInfo.roleCode == 'student' ||
							$store.state.userInfo.roleName == '学生'
					"
				>
					<el-image
						v-if="
							$store.state.userInfo.sexName == '女' ||
								$store.state.userInfo.sex == 0
						"
						:src="$store.state.userInfo.avatar || avatarGirl"
						fit="cover"
						alt="加载失败"
					></el-image>
					<el-image
						v-else
						:src="$store.state.userInfo.avatar || avatarBoy"
						fit="cover"
						alt="加载失败"
					></el-image>
				</div>
				<div v-else>
					<el-image
						v-if="
							$store.state.userInfo.sexName == '女' ||
								$store.state.userInfo.sex == 0
						"
						:src="$store.state.userInfo.avatar || avatarWoman"
						fit="cover"
						alt="加载失败"
					></el-image>
					<el-image
						v-else
						:src="$store.state.userInfo.avatar || avatarMan"
						fit="cover"
						alt="加载失败"
					></el-image>
				</div>
				<p>
					{{
						$store.state.userInfo.real_name ||
							$store.state.userInfo.realName
					}}
					<i
						v-if="
							$store.state.userInfo.sexName === '女' ||
								$store.state.userInfo.sex == 0
						"
						class="iconfont icon-229yonghu_xingbienv"
					></i>
					<i v-else class="iconfont icon-228yonghu_xingbienan"></i>
				</p>
			</div>
			<div class="nav">
				<el-menu
					:default-active="this.$route.path"
					class="el-menu-vertical-demo"
					router
					unique-opened
				>
					<el-submenu
						:index="item.path"
						v-for="(item, i) in navArr"
						:key="i"
					>
						<template slot="title">
							<span>{{ item.name }}</span>
						</template>
						<el-menu-item-group>
							<el-menu-item
								v-for="(val, j) in item.children"
								:key="j"
								:index="val.path"
								>{{ val.name }}</el-menu-item
							>
						</el-menu-item-group>
					</el-submenu>
				</el-menu>
			</div>
		</div>
	</div>
</template>

<script>
import avatarMan from '@/assets/img/avatar_01.gif'
import avatarWoman from '@/assets/img/avatar_02.gif'
import avatarBoy from '@/assets/img/avatar_03.gif'
import avatarGirl from '@/assets/img/avatar_04.gif'
import router, { allRoutes } from '@/router/index.js'
export default {
	data() {
		return {
			// 用户默认头像
			avatarBoy,
			avatarGirl,
			avatarMan,
			avatarWoman,
			navArr: [],
		}
	},
	methods: {
		handleOpen(key, keyPath) {
			console.log(key, keyPath)
		},
		handleClose(key, keyPath) {
			console.log(key, keyPath)
		},
		// 动态路由
		createRouter() {
			// 获取角色
			let role =
				this.$store.state.userInfo.roleCode ||
				this.$store.state.userInfo.role_code
			// 判断传入的路由对象是否有权限
			const decideRouter = (role, router) => {
				if (router.role) {
					return router.role.includes(role)
				} else {
					return true // 路由中没有定义role的是任何角色都可访问。所以为true
				}
			}
			// 过滤出当前角色可以访问的路由数组
			const filRouter = (role, allRoutes) => {
				let arr = allRoutes.filter((item) => {
					if (decideRouter(role, item)) {
						// 递归
						if (item.children) {
							item.children = filRouter(role, item.children)
						}
						return true
					} else {
						return false
					}
				})
				return arr
			}
			// 调用过滤函数得到可访问路由的数组
			let routerArr = filRouter(role, allRoutes)
			// 动态添加路由
			router.addRoutes(routerArr)
			// 过滤出需要在左边导航显示的路由
			this.navArr = routerArr[0].children
		},
	},
	created() {
		this.createRouter()
	},
}
</script>

<style lang="less" scoped>
@title-color: #0079fe;
@btn-color: #f66866;
.left-nav {
	width: 200px;
	margin-right: 30px;
	background-color: #fff;
	.user {
		padding: 20px;
		display: flex;
		flex-direction: column;
		align-items: center;
		border-bottom: 1px solid #eee;
		.el-image {
			width: 100px;
			height: 100px;
			border-radius: 50%;
		}
		p {
			margin: 10px 0;
			color: #888;
			font-size: 14px;
		}
		.iconfont {
			font-size: 14px;
			color: @title-color;
			&.icon-229yonghu_xingbienv {
				color: @btn-color;
			}
		}
	}
	.nav {
		padding: 10px;
		.el-menu {
			border: 0;
			user-select: none;
			/deep/.el-menu-item {
				padding-right: 0px;
				min-width: 180px;
			}
		}
	}
}
</style>

import UserLayout from '@/views/user/userLayout.vue'
import Layout from '@/views/layout/Layout.vue'
export default {
	path: '/user',
	name: 'user',
	component: Layout,
	redirect: '/user/index',
	children: [
		{
			path: '/user/index',
			name: '账户信息',
			role: ['teacher', 'student', 'volunteer'],
			component: UserLayout,
			redirect: '/user/index/userIndex',
			children: [
				{
					path: '/user/index/userIndex',
					name: '个人中心',
					role: ['teacher', 'student'],
					component: () =>
						import('@/views/user/userInfo/userIndex.vue'),
				},
				{
					path: '/user/index/userInfo',
					name: '个人资料',
					role: ['teacher', 'student', 'volunteer'],
					component: () =>
						import('@/views/user/userInfo/userInfo.vue'),
				},
				{
					path: '/user/index/userBaseInfo',
					name: '基础信息',
					role: ['student'],
					component: () =>
						import('@/views/user/userInfo/userBaseInfo.vue'),
				},
				{
					path: '/user/index/myMessage',
					name: '我的消息',
					role: ['teacher', 'student', 'volunteer'],
					component: () =>
						import('@/views/user/userInfo/myMessage.vue'),
				},
			],
		},
		// 账户安全
		{
			path: '/user/safety',
			name: '账户安全',
			role: ['teacher', 'student', 'volunteer'],
			component: UserLayout,
			redirect: '/user/safety/updatePwd',
			children: [
				{
					path: '/user/safety/updatePwd',
					name: '修改密码',
					role: ['teacher', 'student', 'volunteer'],
					component: () =>
						import('@/views/user/safety/updatePwd.vue'),
				},
				// {
				//   path: "/user/safety/updatePhone",
				//   name: "更换手机号",
				//   role: ['teacher', 'student'],
				//   component: () => import("@/views/user/safety/updatePhone.vue"),
				// },
				// {
				// 	path: '/user/safety/bindingWx',
				// 	name: '微信绑定',
				// 	role: ['teacher', 'student', 'volunteer'],
				// 	component: () =>
				// 		import('@/views/user/safety/bindingWx.vue'),
				// },
				// {
				//   path: "/user/safety/privacy",
				//   name: "隐私政策",
				//   role: ['teacher', 'student'],
				//   component: () => import("@/views/user/safety/privacy.vue"),
				// },
			],
		},
		// 预约管理
		{
			path: '/user/order',
			name: '预约管理',
			role: ['teacher', 'student'],
			component: UserLayout,
			redirect: '/user/order/myOrder',
			children: [
				{
					path: '/user/order/myOrder',
					name: '我的预约',
					role: ['teacher', 'student'],
					component: () => import('@/views/user/order/myOrder.vue'),
				},
			],
		},
		// 我的课程
		{
			path: '/user/myCourse',
			name: '我的课程',
			role: ['teacher'],
			component: UserLayout,
			redirect: '/user/myCourse/arrange',
			children: [
				{
					path: '/user/myCourse/arrange',
					name: '课程安排',
					role: ['teacher'],
					component: () =>
						import('@/views/user/myCourse/arrange.vue'),
				},
				{
					path: '/user/myCourse/issue',
					name: '课程发布',
					role: ['teacher'],
					component: () => import('@/views/user/myCourse/issue.vue'),
				},
			],
		},
		// 评价管理
		{
			path: '/user/evaluate',
			name: '评价管理',
			role: ['teacher', 'student'],
			component: UserLayout,
			redirect: '/user/evaluate/evaluate',
			children: [
				{
					path: '/user/evaluate/evaluate',
					name: '我的评价',
					role: ['teacher', 'student'],
					component: () =>
						import('@/views/user/evaluate/evaluate.vue'),
				},
			],
		},
		//问卷调查
		{
			path: 'user/questionNaire',
			name: '问卷调查',
			role: ['student'],
			component: UserLayout,
			redirect: '/user/questionNaire/questionNaire',
			children: [
				{
					path: '/user/questionNaire/questionNaire',
					name: '问卷调查',
					role: ['student'],
					component: () =>
						import('@/views/user/questionNaire/questionNaire.vue'),
				},
				{
					path: '/user/questionNaire/advice',
					name: '平台建议',
					role: ['student'],
					component: () =>
						import('@/views/user/questionNaire/advice.vue'),
				},
			],
		},
		//志愿者服务记录
		{
			path: 'user/volunteer',
			name: '志愿者服务',
			component: UserLayout,
			role: ['volunteer'],
			redirect: '/user/volunteer/volunteerRecord',
			children: [
				{
					path: '/user/volunteer/volunteerRecord',
					name: '服务记录',
					role: ['volunteer'],
					component: () =>
						import('@/views/user/volunteer/volunteerRecord.vue'),
				},
			],
		},
	],
}

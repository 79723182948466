<template>
	<div class="edures">
		<Header></Header>
		<div class="router-view">
			<el-backtop target=".router-view"></el-backtop>
			<router-view></router-view>
		</div>
		<Footer></Footer>
		<Totop />
		<Advice v-if="this.$store.state.isLogin==true"></Advice>
	</div>
</template>

<script>
import Header from './Header.vue'
import Footer from './Footer.vue'
import Totop from '@/components/toTop.vue'
import Advice from '@/components/advice.vue'
export default {
	components: {
		Header,
		Footer,
		Totop,
		Advice,
	},
}
</script>

<style>
.edures {
	background-color: #fff;
	padding-top: 80px;
	position: relative;
}
.router-view {
	min-height: calc(100vh - 275px);
}
</style>
